import * as React from "react"
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import MainArea from  '../components/MainArea';
import ColouredGrid from "../components/ColouredGrid";
import GridPane from "../components/GridPane";
import SideBySide from "../components/SideBySide";
import NextBanner from "../components/NextBanner";
import {Helmet} from "react-helmet";


import { StaticImage } from "gatsby-plugin-image";


// markup
const AboutYouPage = () => {
    return (
        <Layout>
            <Helmet>
                <title>About You | Bluetree Group</title>
                <meta name="description" content="Do you have what it takes? What you need to apply for the Bluetree Group graduate management scheme" />
            </Helmet>
            <Banner title="Do you have what it takes?">
                <StaticImage src={"../assets/banners/aboutyou-banner.jpg"} alt="Employee standing and smiling" />                                
            </Banner>    
            <MainArea>
                <h2 className='intro'>We operate under the banner of six values which all our staff consistently display:</h2>                
                <StaticImage src={"../assets/content/values-1.png"} style={{marginBottom: "2em"}} alt="" />
                <StaticImage src={"../assets/content/values-2.png"} alt="" />
                <SideBySide>
                    <div>
                        <h2>What you'll need</h2>
                        <p>To succeed on the graduate management programme, you’ll need to demonstrate these values. They’re what drive us and create the unique Bluetree culture which makes us so successful and helps us recruit and retain the best people in our sector.</p>
                        <p>You will also need to be a resilient, hard-working and motivated individual who can work well under pressure in a fast-paced environment whilst embracing the challenges that this scheme will present.</p>
                        <p>To join the graduate management programme you will need:</p>
                        <ul className='benefits-list'>
                            <li><img src='/vectors/degree.svg' alt="Degree" /> a 2:1 qualification</li>
                            <li><img src='/vectors/globe.svg' alt="Nationality" /> right to work in the UK</li>
                            <li><img src='/vectors/train.svg' alt="Location" /> to be willing and able to relocate to the Sheffield/Leeds area </li>
                            <li><img src='/vectors/assessment.svg' alt="Assessment" />to attend the February 2023 assessment day (location TBC)</li>
                            <li><img src='/vectors/calendar.svg' alt="Availability" /> availability to start on Summer 2023</li>
                        </ul>
                    </div>
                    <StaticImage src={"../assets/content/Fuji Bluetree Group-46.jpg"} alt="" />
                </SideBySide>
                <NextBanner destination="/faqs">                    
                    <strong>Next:</strong> FAQs
                </NextBanner>
            </MainArea>
        </Layout>      
    )
  }
  
  export default AboutYouPage
  